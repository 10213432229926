import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import TimeAgo from 'react-timeago';

const BlogPage = ({ data }) => {
  const { posts } = data.blog;

  return (
    <Layout pageTitle="Blog">
      <div className="container">
        <div className="p-3 pb-md-4 mx-auto text-center">
          <h1 className="display-4 fw-normal">My blog posts</h1>
        </div>
        <div className="container">
          <div className="row row-cols-sm-1 row-cols-md-2 row-cols-xl-3">
          {posts.map(post => {
            if (post.frontmatter.published) {
              return (<div className="col" key={post.id}>
                <div className="card mt-3 h-100">
                  <div className="card-header">
                    <h5 className="mb-0">{post.frontmatter.title}</h5>
                  </div>
                  <div className="card-body">
                    <p className="card-text">
                      {post.excerpt}
                    </p>
                    <div className="text-center">
                      <Link to={'/blog' + post.fields.slug} className="btn btn-primary w-50">Read</Link>
                    </div>
                  </div>
                  <div className="card-footer text-muted">
                    Written by {post.frontmatter.author}, <TimeAgo date={post.frontmatter.date} />
                  </div>
                </div>
              </div>)
            }
          })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query blogListQuery {
    blog: allMarkdownRemark {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date
          title
          author
          published
        }
        excerpt
        id
      }
    }
  }
`
